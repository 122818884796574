<template>
  <Layout>
    <VContainer>
      <h3 class="text-xs-center">Resto Login</h3>
    </VContainer>
    <VCard width="90%" max-width="300px" class="mx-auto">
      <VCardTitle class="orange lighten-2 white--text">
        <VFlex xs12 mb-2 text-xs-center>
          <h2 class="header-2">{{ getSiteTitle }}</h2>
        </VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-4">
        <AuthForm @success="redirectHome" />
        <div v-if="loggedIn">
          <p class="r-title font-italic"
            >You're already logged in. Head to the main site to start
            editing.</p
          >
        </div>
      </VCardText>
    </VCard>
    <VContainer class="text-xs-center">
      <BaseButton to="/" md-icon="arrow_left" md-icon-position="left" small
        >Back to Main Site</BaseButton
      >
    </VContainer>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import Layout from '@layouts/DefaultLayout'
import AuthForm from '@auth/AuthForm'

export default {
  page() {
    return {
      title: 'Resto Sign In',
      meta: [
        { name: 'description', content: `Sign into ${this.getSiteTitle}` },
      ],
    }
  },
  components: { Layout, AuthForm },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('auth', ['loggedIn']),
    ...mapFields('auth', ['isLoginSuccessSnackbarVisible']),
  },
  methods: {
    redirectHome() {
      setTimeout(() => {
        this.isLoginSuccessSnackbarVisible = true
      }, 1000)

      this.$router.push('/')
    }, // redirectHome
  },
}
</script>
