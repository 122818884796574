<template>
  <div>
    <VContent class="view-content">
      <VContainer fluid pa-0>
        <slot />
      </VContainer>
    </VContent>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
  components: {},
}
</script>
